import {Link} from "gatsby"
import React from "react"
import Styles from "./languageBar.module.scss"
import {LocaleContext} from "../layout";

const LanguageBar = () => {
    const { locale } = React.useContext(LocaleContext)

    return (
        <div className={`${Styles.bar}`}>
            {(locale === 'ja')?
                <a className={Styles.registrationLink} href={"/support/registration"}>お客様製品登録</a>
                :
                null
            }
            <div className={Styles.links}>
                {(locale === 'ja')?
                    <Link to="/en" hrefLang="en" className={Styles.link}>
                        <span className={`${Styles.usFlag} img-box`}></span>
                        English
                    </Link>:
                    <Link to="/" hrefLang="ja" className={`${Styles.link}`}>
                        <span className={`${Styles.jpFlag} img-box`}></span>
                        日本語
                    </Link>
                }
            </div>
        </div>
    )
}

export default LanguageBar