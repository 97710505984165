import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import {LocaleContext} from "./layout";

const useTranslationsCat = () => {
    // Grab the locale (passed through context) from the Context Provider
    const {locale} = React.useContext(LocaleContext);
    // Query the JSON files in <rootDir>/i18n/translations
    const {rawData} = useStaticQuery(query);

    // Simplify the response from GraphQL
    const simplified = rawData.edges.map((item) => {
        return {
            name: item.node.name,
            translations: item.node.translations,
        };
    });

    // Only return translations for the current locale
    const {translations} = simplified.filter((lang) => lang.name === locale)[0];

    return translations;
};

export default useTranslationsCat;

const query = graphql`
	query useTranslationsCat {
		rawData: allFile(filter: { sourceInstanceName: { eq: "translationsCat" } }) {
			edges {
				node {
					name
					translations: childTranslationsCatJson {
                        thermometers
                        digitalThermometers
                        dataloggersTemperature
                        analogThermometers
                        thermographs
                        remoteSensingDialThermometers
                        bimetalThermometers
                        standardGlassThermometers
                        glassThermometers
                        thermocouples
                        resistanceTemperatureDetectors
                        thermohygrometers
                        digitalThermohygrometers
                        dataloggersTemperatureHumidity
                        transmitters
                        dialThermohygrometers
                        thermohygrographs
                        psychrometers
                        anemometers
                        heatStressMonitors
                        infraredThermometers
                        barometers
                        digitalBarometer
                        dialBarometers
                        barograph
                        recorders
                        indicators
                        weatherInstruments
                        windSpeedDirectionIndicator
                        anemometersWeatherInstruments
                        rainGauge
                        loggers
                        instrumentScreens
                        moistureMeters
                        soilPhMetersSaltMetersCo2MetersOtheres
                        refractometers
                        phMeters
                        timers
                        timersSecondLayer
                        stopwatches
                        sandglasses
                        optionalProbes
                        forSkL751
                        forSkL754
                        forSkL700rT
                        forSkL700rTh
                        forSkL200TIISeries
                        forSkL200th2aSeries
                        forSkL210t
                        forSkL400tSk1110Sk1120Sk7000PRTII
                        forSk1260
                        forSk1250MCIIISk1250MCIIIa
                        forSk270wpSeries
                        forSk250wp2Series
                        forSk100wp
                        forSk810pt
                        forSkRhcSeries
                        forSkM460TSkM350T
                        forSkM350rT
                        forSkM350rTrh
                        forSk610ph2
                        forSk660phSeries
                        forSk620ph2Sk650ph
                        forSk620ph
                        chartsAndPens
                        forSigma2Thermohygrographs
                        forAurora903Thermohygrograph
                        forSigma2Thermograph
                        forSigma2Hygrograph
                        forSigma2Barograph
                        forSigma2RemoteThermographs
                        forMiniCubeThermohygrograph
                        forMiniStarThermohygrograph
                        forMiniAlphaThermohygrograph
                        forEventRecorder
                        forLmmcTemperatureRecorder
                        forVariousRecorders
                        cartridgePens
                        otherAccessories
                        discontinuedProducts
					}
				}
			}
		}
	}
`;
