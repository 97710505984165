import React from "react";
import LocalizedLink from "../localizedLink";
import useTranslationsCat from "../useTranslationsCat";

const CategoryNav = () => {
const {
thermometers,
digitalThermometers,
dataloggersTemperature,
analogThermometers,
thermographs,
remoteSensingDialThermometers,
bimetalThermometers,
standardGlassThermometers,
glassThermometers,
thermocouples,
resistanceTemperatureDetectors,
thermohygrometers,
digitalThermohygrometers,
dataloggersTemperatureHumidity,
transmitters,
dialThermohygrometers,
thermohygrographs,
psychrometers,
anemometers,
heatStressMonitors,
infraredThermometers,
barometers,
digitalBarometer,
dialBarometers,
barograph,
recorders,
indicators,
weatherInstruments,
windSpeedDirectionIndicator,
anemometersWeatherInstruments,
rainGauge,
loggers,
instrumentScreens,
moistureMeters,
soilPhMetersSaltMetersCo2MetersOtheres,
refractometers,
phMeters,
timers,
timersSecondLayer,
stopwatches,
sandglasses,
optionalProbes,
forSkL751,
forSkL754,
forSkL700rT,
forSkL700rTh,
forSkL200TIISeries,
forSkL200th2aSeries,
forSkL210t,
forSkL400tSk1110Sk1120Sk7000PRTII,
forSk1260,
forSk1250MCIIISk1250MCIIIa,
forSk270wpSeries,
forSk250wp2Series,
forSk100wp,
forSk810pt,
forSkRhcSeries,
forSkM460TSkM350T,
forSkM350rT,
forSkM350rTrh,
forSk610ph2,
forSk660phSeries,
forSk620ph2Sk650ph,
forSk620ph,
chartsAndPens,
forSigma2Thermohygrographs,
forAurora903Thermohygrograph,
forSigma2Thermograph,
forSigma2Hygrograph,
forSigma2Barograph,
forSigma2RemoteThermographs,
forMiniCubeThermohygrograph,
forMiniStarThermohygrograph,
forMiniAlphaThermohygrograph,
forEventRecorder,
forLmmcTemperatureRecorder,
forVariousRecorders,
cartridgePens,
otherAccessories,
discontinuedProducts,
} = useTranslationsCat();
const ActiveStyle = {
backgroundColor: '#033364',
color: '#fff',
width: 'auto',
textAlign: 'center',
}

return (
<ul className="menu__second-level">
<li>
<LocalizedLink to="/categories/thermometers/" activeStyle={ActiveStyle} partiallyActive={ActiveStyle} className="init-right line">{thermometers}</LocalizedLink>
<ul className="menu__third-level">
<li>
<LocalizedLink to="/categories/thermometers/digital-thermometers/" activeStyle={ActiveStyle} className="init-right line">{digitalThermometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/dataloggers-temperature/" activeStyle={ActiveStyle} className="init-right line">{dataloggersTemperature}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/analog-thermometers/" activeStyle={ActiveStyle} className="init-right line">{analogThermometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/thermographs/" activeStyle={ActiveStyle} className="init-right line">{thermographs}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/remote-sensing-dial-thermometers/" activeStyle={ActiveStyle} className="init-right line">{remoteSensingDialThermometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/bimetal-thermometers/" activeStyle={ActiveStyle} className="init-right line">{bimetalThermometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/standard-glass-thermometers/" activeStyle={ActiveStyle} className="init-right line">{standardGlassThermometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/glass-thermometers/" activeStyle={ActiveStyle} className="init-right line">{glassThermometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/thermocouples/" activeStyle={ActiveStyle} className="init-right line">{thermocouples}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/resistance-temperature-detectors/" activeStyle={ActiveStyle} className="init-right line">{resistanceTemperatureDetectors}</LocalizedLink>
</li>
</ul>
</li>
<li>
<LocalizedLink to="/categories/thermohygrometers/" activeStyle={ActiveStyle} partiallyActive={ActiveStyle} className="init-right line">{thermohygrometers}</LocalizedLink>
<ul className="menu__third-level">
<li>
<LocalizedLink to="/categories/thermohygrometers/digital-thermohygrometers/" activeStyle={ActiveStyle} className="init-right line">{digitalThermohygrometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermohygrometers/dataloggers-temperature-Humidity/" activeStyle={ActiveStyle} className="init-right line">{dataloggersTemperatureHumidity}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermohygrometers/transmitters/" activeStyle={ActiveStyle} className="init-right line">{transmitters}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermohygrometers/dial-thermohygrometers/" activeStyle={ActiveStyle} className="init-right line">{dialThermohygrometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermohygrometers/thermohygrographs/" activeStyle={ActiveStyle} className="init-right line">{thermohygrographs}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermohygrometers/psychrometers/" activeStyle={ActiveStyle} className="init-right line">{psychrometers}</LocalizedLink>
</li>
</ul>
</li>
<li>
<LocalizedLink to="/categories/anemometers/" activeStyle={ActiveStyle} partiallyActive={ActiveStyle} className="init-right line">{anemometers}</LocalizedLink>
<ul className="menu__third-level">
</ul>
</li>
<li>
<LocalizedLink to="/categories/heat-stress-monitors/" activeStyle={ActiveStyle} partiallyActive={ActiveStyle} className="init-right line">{heatStressMonitors}</LocalizedLink>
<ul className="menu__third-level">
</ul>
</li>
<li>
<LocalizedLink to="/categories/infrared-thermometers/" activeStyle={ActiveStyle} partiallyActive={ActiveStyle} className="init-right line">{infraredThermometers}</LocalizedLink>
<ul className="menu__third-level">
</ul>
</li>
<li>
<LocalizedLink to="/categories/barometers/" activeStyle={ActiveStyle} partiallyActive={ActiveStyle} className="init-right line">{barometers}</LocalizedLink>
<ul className="menu__third-level">
<li>
<LocalizedLink to="/categories/barometers/digital-barometer/" activeStyle={ActiveStyle} className="init-right line">{digitalBarometer}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/barometers/dial-barometers/" activeStyle={ActiveStyle} className="init-right line">{dialBarometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/barometers/barograph/" activeStyle={ActiveStyle} className="init-right line">{barograph}</LocalizedLink>
</li>
</ul>
</li>
<li>
<LocalizedLink to="/categories/recorders/" activeStyle={ActiveStyle} partiallyActive={ActiveStyle} className="init-right line">{recorders}</LocalizedLink>
<ul className="menu__third-level">
</ul>
</li>
<li>
<LocalizedLink to="/categories/indicators/" activeStyle={ActiveStyle} partiallyActive={ActiveStyle} className="init-right line">{indicators}</LocalizedLink>
<ul className="menu__third-level">
</ul>
</li>
<li>
<LocalizedLink to="/categories/weather-instruments/" activeStyle={ActiveStyle} partiallyActive={ActiveStyle} className="init-right line">{weatherInstruments}</LocalizedLink>
<ul className="menu__third-level">
<li>
<LocalizedLink to="/categories/weather-instruments/wind-speed-direction-indicator/" activeStyle={ActiveStyle} className="init-right line">{windSpeedDirectionIndicator}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/weather-instruments/anemometers-weather-instruments/" activeStyle={ActiveStyle} className="init-right line">{anemometersWeatherInstruments}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/weather-instruments/rain-gauge/" activeStyle={ActiveStyle} className="init-right line">{rainGauge}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/weather-instruments/loggers/" activeStyle={ActiveStyle} className="init-right line">{loggers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/weather-instruments/instrument-screens/" activeStyle={ActiveStyle} className="init-right line">{instrumentScreens}</LocalizedLink>
</li>
</ul>
</li>
<li>
<LocalizedLink to="/categories/moisture-meters/" activeStyle={ActiveStyle} partiallyActive={ActiveStyle} className="init-right line">{moistureMeters}</LocalizedLink>
<ul className="menu__third-level">
</ul>
</li>
<li>
<LocalizedLink to="/categories/soil-ph-meters-salt-meters-co2-meters-otheres/" activeStyle={ActiveStyle} partiallyActive={ActiveStyle} className="init-right line">{soilPhMetersSaltMetersCo2MetersOtheres}</LocalizedLink>
<ul className="menu__third-level">
</ul>
</li>
<li>
<LocalizedLink to="/categories/refractometers/" activeStyle={ActiveStyle} partiallyActive={ActiveStyle} className="init-right line">{refractometers}</LocalizedLink>
<ul className="menu__third-level">
</ul>
</li>
<li>
<LocalizedLink to="/categories/ph-meters/" activeStyle={ActiveStyle} partiallyActive={ActiveStyle} className="init-right line">{phMeters}</LocalizedLink>
<ul className="menu__third-level">
</ul>
</li>
<li>
<LocalizedLink to="/categories/timers/" activeStyle={ActiveStyle} partiallyActive={ActiveStyle} className="init-right line">{timers}</LocalizedLink>
<ul className="menu__third-level">
<li>
<LocalizedLink to="/categories/timers/timers-second-layer/" activeStyle={ActiveStyle} className="init-right line">{timersSecondLayer}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/timers/stopwatches/" activeStyle={ActiveStyle} className="init-right line">{stopwatches}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/timers/sandglasses/" activeStyle={ActiveStyle} className="init-right line">{sandglasses}</LocalizedLink>
</li>
</ul>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/" activeStyle={ActiveStyle} partiallyActive={ActiveStyle} className="init-right line">{optionalProbes}</LocalizedLink>
<ul className="menu__third-level">
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-l751/" activeStyle={ActiveStyle} className="init-right line">{forSkL751}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-l754/" activeStyle={ActiveStyle} className="init-right line">{forSkL754}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-l700r-t/" activeStyle={ActiveStyle} className="init-right line">{forSkL700rT}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-l700r-th/" activeStyle={ActiveStyle} className="init-right line">{forSkL700rTh}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-l200TII-Series/" activeStyle={ActiveStyle} className="init-right line">{forSkL200TIISeries}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-l200th2a-series/" activeStyle={ActiveStyle} className="init-right line">{forSkL200th2aSeries}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-l210t/" activeStyle={ActiveStyle} className="init-right line">{forSkL210t}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-l400t-sk-1110-sk-1120-sk-7000PRTII/" activeStyle={ActiveStyle} className="init-right line">{forSkL400tSk1110Sk1120Sk7000PRTII}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-1260/" activeStyle={ActiveStyle} className="init-right line">{forSk1260}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-1250MCIII-sk-1250MCIIIa/" activeStyle={ActiveStyle} className="init-right line">{forSk1250MCIIISk1250MCIIIa}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-270wp-series/" activeStyle={ActiveStyle} className="init-right line">{forSk270wpSeries}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-250wp2-series/" activeStyle={ActiveStyle} className="init-right line">{forSk250wp2Series}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-100wp/" activeStyle={ActiveStyle} className="init-right line">{forSk100wp}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-810pt/" activeStyle={ActiveStyle} className="init-right line">{forSk810pt}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-rhc-series/" activeStyle={ActiveStyle} className="init-right line">{forSkRhcSeries}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-m460-t-sk-m350-t/" activeStyle={ActiveStyle} className="init-right line">{forSkM460TSkM350T}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-m350r-t/" activeStyle={ActiveStyle} className="init-right line">{forSkM350rT}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-m350r-trh/" activeStyle={ActiveStyle} className="init-right line">{forSkM350rTrh}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-610ph-2/" activeStyle={ActiveStyle} className="init-right line">{forSk610ph2}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-660ph-series/" activeStyle={ActiveStyle} className="init-right line">{forSk660phSeries}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-620ph2-sk-650ph/" activeStyle={ActiveStyle} className="init-right line">{forSk620ph2Sk650ph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-620ph/" activeStyle={ActiveStyle} className="init-right line">{forSk620ph}</LocalizedLink>
</li>
</ul>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/" activeStyle={ActiveStyle} partiallyActive={ActiveStyle} className="init-right line">{chartsAndPens}</LocalizedLink>
<ul className="menu__third-level">
<li>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-thermohygrographs/" activeStyle={ActiveStyle} className="init-right line">{forSigma2Thermohygrographs}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-aurora90-3-thermohygrograph/" activeStyle={ActiveStyle} className="init-right line">{forAurora903Thermohygrograph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-thermograph/" activeStyle={ActiveStyle} className="init-right line">{forSigma2Thermograph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-hygrograph/" activeStyle={ActiveStyle} className="init-right line">{forSigma2Hygrograph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-barograph/" activeStyle={ActiveStyle} className="init-right line">{forSigma2Barograph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-remote-thermographs/" activeStyle={ActiveStyle} className="init-right line">{forSigma2RemoteThermographs}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-mini-cube-thermohygrograph/" activeStyle={ActiveStyle} className="init-right line">{forMiniCubeThermohygrograph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-mini-star-thermohygrograph/" activeStyle={ActiveStyle} className="init-right line">{forMiniStarThermohygrograph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-mini-alpha-thermohygrograph/" activeStyle={ActiveStyle} className="init-right line">{forMiniAlphaThermohygrograph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-event-recorder/" activeStyle={ActiveStyle} className="init-right line">{forEventRecorder}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-lmmc-temperature-recorder/" activeStyle={ActiveStyle} className="init-right line">{forLmmcTemperatureRecorder}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-various-recorders/" activeStyle={ActiveStyle} className="init-right line">{forVariousRecorders}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/cartridge-pens/" activeStyle={ActiveStyle} className="init-right line">{cartridgePens}</LocalizedLink>
</li>
</ul>
</li>
<li>
<LocalizedLink to="/categories/other-accessories/" activeStyle={ActiveStyle} partiallyActive={ActiveStyle} className="init-right line">{otherAccessories}</LocalizedLink>
<ul className="menu__third-level">
</ul>
</li>
<li>
<LocalizedLink to="/categories/discontinued-products/" activeStyle={ActiveStyle} partiallyActive={ActiveStyle} className="init-right line">{discontinuedProducts}</LocalizedLink>
<ul className="menu__third-level">
</ul>
</li>
</ul>
);
};

export default CategoryNav;