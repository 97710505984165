import React from "react"
import LocalizedLink from "./localizedLink"

const isHash = str => /^#/.test(str)
const isInternal = to => /^\/(?!\/)/.test(to)

// Only use <LocalizedLink /> for internal links
const MdxLink = ({ children, href, ...props }) =>
  isHash(href) || !isInternal(href) ? (
      <a {...props} href={href}>{children}</a>
  ) : (
      <LocalizedLink {...props} to={href}>{children}</LocalizedLink>
  )

export default MdxLink
